import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Ääähm, man könnte auch das die von React bereitgestellte NavLink-Komponente benutzen. Ist mir aber erst zu spät aufgefallen. Umbauen erwünscht. :D
 */

export default function NavElement (props) {

    return <li className={ `nav-element ${props.className}` } onClick={ () => props.onClick() } >
        <Link to={ props.route }><i className={ props.icon }></i></Link>
    </li>
}