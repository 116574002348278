import { useState } from "react";
import { convertDue } from "./utils/listEntryUtils";

export const ToDoListEntry = props => {
    const [isActive, setIsActive] = useState(null);
    const data = props.data;

    const setContainerIsActive = () => {
        if(isActive === null) {
            setIsActive(true);
        } else {
            setIsActive(!isActive);
        }
    }

    const getContainerClassName = () => isActive ? "active" : "inactive";

    const subjectTitle = data.subject?.title ? 
                            data.subject?.title?.length > (data.tag ? 18 : 26) ?
                                `${data.subject?.title.slice(0, (data.tag ? 18 : 26))}...`
                                : data.subject?.title
                            : <span>&ndash;</span>;
                            
    return <div className={`entry-container ${isActive === null ? "" : getContainerClassName()}`}>
                <div className="entry-settings">
                    <div
                        className="entry-setting success" 
                        onClick={ () => { setIsActive(false); props.checkItem(); } }>
                            <i className="fas fa-check"></i>
                    </div>
                    <div 
                        className="entry-setting info" 
                        onClick={() => { setIsActive(false); props.openSettings(data); } }>
                            <i className="bi bi-gear-fill"></i>
                    </div>
                </div>
                <div className="list-entry todo" onClick={ () => setContainerIsActive() }>
                    <div className="title">{ data.title }</div>
                    <div className="content">
                        <div className="information-area">
                            <div>
                                <i className="bi bi-book"></i> { subjectTitle }
                            </div>
                            <div>
                                <i className="bi bi-clock"></i> { convertDue(data.due) }
                            </div>
                        </div>
                        <div className="tag-area">
                            { data.tag ? <div className="tag" style={ {backgroundColor: data.tag.color} }>{ data.tag.title }</div> : null }
                        </div>
                    </div>
                </div>
            </div>
}