import { fetchGet, fetchPost } from './apiUtils';

const validateAuthToken = async () => {
    try {
        const response = await fetchGet("/Auth/validate");
        const res = await response.json();
        return res.valid;
    } catch {
        return false;
    }
};

const login = token => {
    if(validateAuthToken(token)) {
        setAuthToken(token);
        window.location.reload(false);
    } else {
        throw new Error("token not valid");
    }
}

const logout = () => {
    removeAuthToken();
    window.location = "/";
}

const getAuthToken = () => localStorage.getItem("auth");
const setAuthToken = token => localStorage.setItem("auth", token);
const removeAuthToken = () => localStorage.removeItem("auth");
const authTokenIsSet = async () => await validateAuthToken();

export { getAuthToken, authTokenIsSet, login, logout };