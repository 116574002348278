import { React, useContext, useEffect, useState } from 'react';
import { map, reject, isNil } from 'ramda';
import { Headline, SubHeadline } from '../../components/elements/Typography';
import { ButtonRow, IconButton } from '../../components/form/Button';
import { List } from '../../components/List';
import { ApiContext } from '../context/ApiContext';
import { ToDoListEntryModal } from './modals/ToDoListEntryModal';
import { ToDoListEntry } from './ToDoListEntry';
import { TODO_LIST_ENTRY_DEFAULT } from './utils/listEntryUtils';

const getHeadline = () => {
  const date = new Date();
  const currentTime =  `${date.getHours().toString().length === 1 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes()}`;
  if(currentTime >= "04:30" && currentTime < "11:00") return "Guten Morgen,";
  if(currentTime >= "11:00" && currentTime < "14:00") return "Guten Mittag,";
  if(currentTime >= "14:00" && currentTime < "18:00") return "Guten Tag,";
  return "Guten Abend,";
}

const getSubHeadline = itemsCount => {
  const highlightedText = itemsCount === 0 ? "keine Aufgabe" : itemsCount === 1 ? "eine Aufgabe" : `${ itemsCount } Aufgaben`;
  return <>du hast <span className="highlighted">{ highlightedText }</span> auf deiner Liste.</>
}

export default function ToDoList (props) {
  const apiContext = useContext(ApiContext);
  const [ entryModal, setEntryModal ] = useState(null);
  const [ todoEntries, setTodoEntries ] = useState([])

  useEffect(() => {
    const getTodoItems = async () => {
      const items = await apiContext.toDo.getItems();
      // remove done items
      const filteredItems = reject(isNil, map(item => { if(item.done === false) return item }, items));
      setTodoEntries(filteredItems);
    }
    getTodoItems();
  }, [apiContext])
  
  const updateItems = () => {
    const getTodoItems = async () => {
      const items = await apiContext.toDo.getItems();
      // remove done items
      const filteredItems = reject(isNil, map(item => { if(item.done === false) return item }, items));
      setTodoEntries(filteredItems);
    }
    getTodoItems();
  };

  const headline = getHeadline();
  const subheadline = getSubHeadline(todoEntries.length === undefined ? 0 : todoEntries.length);

  const checkItem = id => {
    const res = apiContext.toDo.checkItem(id);
    res.then(() => updateItems());
  }

  return  <>
            { entryModal ? <ToDoListEntryModal data={ entryModal } closeModalAction={() => { setEntryModal(null); updateItems(); }} /> : null }
            <div className="page-container">
              <Headline value={ headline } />  
              <SubHeadline value={ subheadline } />
              <ButtonRow>
                <IconButton color="primary" icon="bi bi-plus-lg" onClick={ () => setEntryModal(TODO_LIST_ENTRY_DEFAULT)} />
                { /* <IconButton color="secondary" icon="bi bi-funnel-fill" /> */ }
              </ButtonRow>
              <List>
                { map(item => <ToDoListEntry key={ item.id } data={ item } openSettings={ setEntryModal } checkItem={ () => checkItem(item.id) } />, todoEntries) }
              </List>
            </div>
          </>
}