import React, { useState, useContext, useEffect } from 'react';
import { ApiContext } from '../context/ApiContext';
import { SubjectModal } from './modals/SubjectModal';

const Item = props => <span className="item" onClick={ () => props.openModal() }>{ props.data.title }</span>;

const EMPTY_SUBJECT = {
    id: 0,
    title: "",
    lecturer: ""
};

export const SubjectSettings = props => {
    const apiContext = useContext(ApiContext);
    const [subjectModal, setSubjectModal] = useState(null)
    const [subjects, setSubjects] = useState([EMPTY_SUBJECT]);

    useEffect(() => {
        const getSubjects = async () => {
          const subjects = await apiContext.subjects.getItems();
          setSubjects(subjects);
        }
        getSubjects();
      }, [apiContext])

    const reload = () => {
        const getSubjects = async () => {
            const subjects = await apiContext.subjects.getItems();
            setSubjects(subjects);
        }
        getSubjects();
    }

    return <>
       { subjectModal ? <SubjectModal
                            data={ subjectModal }
                            closeModalAction={ () => { setSubjectModal(null); reload(); } }/> : null} 
        <div className="headline">
            <h3>Fächer</h3>
        </div>
        <div className="items">
            { subjects.map(subject => <Item key={subject.id} data={subject} openModal={() => setSubjectModal(subject)} />) }
            <span className="item" onClick={ () => setSubjectModal({id: 0, title:""}) }><i className="bi bi-plus"></i></span>
        </div>
    </>
}