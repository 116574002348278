import { React, useState } from 'react';
import NavElement from './NavElement';
import './Navigation.scss';

export default function Navigation() {
    const [state, setState] = useState(1);

    const isActive = key => state === key ? "active" : null;
    
    return (
      <ul className="bottom-navigation">
        <NavElement icon="bi bi-calendar3" route="/timetable" className={ isActive(0) } onClick={ () => setState(0) } />
        <NavElement icon="bi bi-list-check" route="/" className={ isActive(1) } onClick={ () => setState(1) } />
        <NavElement icon="bi bi-gear-fill" route="/settings/" className={ isActive(2) } onClick={ () => setState(2) } />
      </ul>
    );
  }