import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import './index.css';
import App from './App/';
import AuthentificationPage from './AuthentificationPage';


import { ApiContext, defaultApiContext } from './App/context/ApiContext';
import { AppContainer } from './AppContainer';

ReactDOM.render(
  <React.StrictMode>
    <ApiContext.Provider value={ defaultApiContext }>
      <AppContainer />
    </ApiContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();