import React, { useState, useContext } from 'react';

import { Modal } from "../../../components/modal/Modal"
import { Button, ButtonRow } from '../../../components/form/Button';
import InputField from "../../../components/form/InputField";
import { ApiContext } from '../../context/ApiContext';
import Alert from '../../../components/elements/Alert';

const ColorSelection = props => {
  const possibleColors = [
    "#FF5252",
    "#283595",
    "#6A1B9A",
    '#F5BA45',
    '#429553',
    '#424953'
  ];

  const isSelected = color => color === props.value ? "selected" : "";

  return <div className="input-group">
          <label className="input-title">
            { props.title }
          </label>
          <div className="color-seletion">
            { possibleColors.map(color => <span key={color} className={ `selectable-color ${isSelected(color)}`} style={{backgroundColor: color}}
                                            onClick={() => props.onChange(color)}> </span>) }
          </div>
        </div>
}

export const TagModal = props => {
    const apiContext = useContext(ApiContext);
    const [title, setTitle] = useState(props.data.title);
    const [color, setColor] = useState(props.data.color);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const saveInputs = () => {
      setLoadingSpinner(true);

      if(title === "") {
        setErrorMessage("Der Titel darf nicht leer sein.")
        setLoadingSpinner(false);
        return;
      }
        
      if(props.data.id === 0) {
          apiContext.tags.addItem({
            id: props.data.id,
            title: title,
            color: color,
            colour: color
        }).then(success => {
          if(success) {
            setLoadingSpinner(false);
            document.querySelector("#active-modal").classList.add("hide-modal");
            window.setTimeout(() => props.closeModalAction(), 500);
          } else {
            setErrorMessage("Bitte überprüfe deine angegebenen Daten.")
            setLoadingSpinner(false);
          }
        });

        document.querySelector("#active-modal").classList.add("hide-modal");
        window.setTimeout(() => props.closeModalAction(), 500);
      } else {
        apiContext.tags.saveItem({
            id: props.data.id,
            title: title,
            color: color,
            colour: color
        }).then(success => {
          if(success) {
            setLoadingSpinner(false);
            document.querySelector("#active-modal").classList.add("hide-modal");
            window.setTimeout(() => props.closeModalAction(), 500);
          } else {
            setErrorMessage("Bitte überprüfe deine angegebenen Daten.")
            setLoadingSpinner(false);
          }
        });

        document.querySelector("#active-modal").classList.add("hide-modal");
        window.setTimeout(() => props.closeModalAction(), 500);
      }
    }

    const deleteItem = () => {
      apiContext.tags.deleteItem(props.data.id).then(success => {
        if(success) {
          setLoadingSpinner(false);
          document.querySelector("#active-modal").classList.add("hide-modal");
          window.setTimeout(() => props.closeModalAction(), 500);
        } else {
          setErrorMessage("Fehler beim Löschen des Eintrages.")
          setLoadingSpinner(false);
        }
      });

      document.querySelector("#active-modal").classList.add("hide-modal");
      window.setTimeout(() => props.closeModalAction(), 500);
    }

    return <Modal
      className="darken"
      title={ props.data.id === 0 ? "Tag erstellen" : "Tag bearbeiten" }
      closeModalAction={() => props.closeModalAction()}
      onSubmit={saveInputs}
      loadingSpinner={loadingSpinner}>

      { errorMessage ? <Alert type="danger" message={errorMessage} /> : undefined}

        <InputField 
          className="w-100"
          onChange={title => setTitle(title)}
          value={ title }
          title="Titel"
          placeholder="Allgemein"
          />
        <ColorSelection 
          className="w-100"
          onChange={setColor}
          value={ color }
          title="Farbe"
          />

        { props.data.id !== 0 ?
        <ButtonRow className="btn-fit-2 no-margin">
          <Button
            className="btn-secondary-dark w-100"
            onClick={deleteItem}
            value="Löschen"
            />

          <Button
            className="btn-primary w-100"
            onClick={saveInputs}
            value="Speichern"
            />
        </ButtonRow> : <Button
            className="btn-primary w-100"
            onClick={saveInputs}
            value="Speichern"
            /> }
          
    </Modal>;
}