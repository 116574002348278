import React, { useState } from 'react';
import InputField from '../../components/form/InputField';
import { Button } from '../../components/form/Button';
import { getAuthToken } from '../../utils/authUtils';
import { login } from '../../utils/tokenUtils';
import { Modal } from '../../components/modal/Modal';
import Alert from '../../components/elements/Alert';

export const LoginModal = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const validateInputs = () => {
    setLoadingSpinner(true);
    getAuthToken(username, password).then(token => {
      login(token);
    }).catch((e) => {
      setErrorMessage(e.message)
    }).finally(() => {
      setLoadingSpinner(false);
    });
  }

  return (
    <Modal
      className="ligthen"
      title="Willkommen zurück!"
      description="Bitte logge dich in deinem Account ein."
      closeModalAction={() => props.closeModalAction()}
      loadingSpinner={loadingSpinner}
      onSubmit={validateInputs}>

      { errorMessage ? <Alert type="danger" message={errorMessage} /> : undefined}
      

      <InputField 
          className="w-100"
          onChange={username => setUsername(username)}
          value={username}
          title="Benutzername"
          placeholder="Max Mustermann"
          />

      <InputField
          className="w-100"
          type="password"
          onChange={password => setPassword(password)}
          value={password}
          title="Passwort"
          placeholder=""
          />

      <Button
          className="btn-primary w-100"
          onClick={validateInputs}
          value="Anmelden"
          />
    </Modal>
  );
}