import React, { useEffect, useState, useContext } from 'react';
import { filter } from 'ramda';
import { DateTime } from 'luxon';

import { Modal } from "../../../components/modal/Modal"
import { Button } from '../../../components/form/Button';
import InputField from "../../../components/form/InputField";
import { SelectField } from '../../../components/form/SelectField';
import { ApiContext } from '../../context/ApiContext';
import Alert from '../../../components/elements/Alert';
import { WeekdaySelect } from '../../../components/form/WeekdaySelect';

const EMPTY_SUBJECT = {
    id: 0,
    title: "",
    lecturer: ""
  };

const getDateForWeekday = weekday => {
    const today = DateTime.now();
    const date = today.plus({days: (weekday-today.weekday)}).plus({days: 7});
    return date.toISODate();
}

export const NewTimeTableEntryModal = props => {
    const apiContext = useContext(ApiContext);

    const [subjects, setSubjects] = useState([EMPTY_SUBJECT]);
    const [subject, setSubject] = useState(props.data.subject.title);
    const [room, setRoom] = useState(props.data.room);
    const [weekday, setWeekday] = useState(props.day);
    const [beginHour, setBeginHour] = useState(props.data.beginHour);
    const [endHour, setEndHour] = useState(props.data.endHour);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    useEffect(() => {
        const getSubjects = async () => {
            const subjects = await apiContext.subjects.getItems();
            setSubjects(subjects);
            if(subject === "") setSubject(subjects[0].title)
          }
          getSubjects();
    }, [apiContext]);

    const saveInputs = () => {
        if(beginHour > endHour) {
            setErrorMessage("Der Endzeitpunkt darf nicht vor dem Startzeitpunkt liegen.");
            setLoadingSpinner(false);
            return;
        }
        
        let subjectToSave = filter(obj => obj.title === subject, subjects)[0];
        subjectToSave = subjectToSave === undefined ? subject : subjectToSave;

        apiContext.calendar.addItem({
            id: 0,
            date: getDateForWeekday(weekday),
            schedule: {
                id: 0,
                beginHour: beginHour,
                endHour: endHour,
                room: room,
                subject: subjectToSave
            }
        }).then(() => {

            setLoadingSpinner(false);
            document.querySelector("#active-modal").classList.add("hide-modal");
            window.setTimeout(() => props.closeModalAction(), 500);
        }).catch(() => {
            setErrorMessage("Es ist ein Fehler aufgetreten.");
            setLoadingSpinner(false);
        });
    }

    return <Modal
      className="darken"
      title={ "Eintrag erstellen" }
      closeModalAction={() => props.closeModalAction()}
      loadingSpinner={loadingSpinner}
      onSubmit={saveInputs}>

        { errorMessage ? <Alert type="danger" message={errorMessage} /> : undefined}

        <WeekdaySelect
            day={ weekday }
            setWeekday={ setWeekday }
            />
        
        <InputField 
          className="w-100"
          type="time"
          onChange={time => setBeginHour(time) }
          value={ beginHour }
          w50={ true }
          title="Von"
          />
        <div className="input-spacer"></div>
        <InputField 
          className="w-100"
          type="time"
          onChange={time => setEndHour(time) }
          value={ endHour }
          w50={ true }
          title="Bis"
          />

        <SelectField 
          className="w-100"
          onChange={subject => setSubject(subject)}
          value={ subject }
          possibleValues={ subjects }
          title="Fach"
          placeholder="Allgemein"
          />

        <InputField 
          className="w-100"
          onChange={room => setRoom(room)}
          value={ room }
          title="Raum"
          placeholder="l032a"
        />

        <Button
          className="btn-primary w-100"
          onClick={saveInputs}
          value="Speichern"
          />
      </Modal>
} 