import React from 'react';
import './Modal.scss';

export const Modal = (props) => {
    const unmountModal = () => {
        document.querySelector("#active-modal").classList.add("hide-modal");
        window.setTimeout(() => props.closeModalAction(), 500);
    }

    const formAction = (event, callback) => {
        event.preventDefault();
        callback();
    }
    
    return (
        <form className={ `modal-container ${props.className}` } id="active-modal" onSubmit={ e => formAction(e, props.onSubmit) }>
            <div onClick={() => unmountModal()} className="modal-background"></div>
            <div className="modal">
                <div className="modal-header">
                    { props.title ? (<h1 className="modal-title">{ props.title }</h1>) : null }
                    { props.description ? (<p className="modal-description">{ props.description }</p>) : null }
                    <span onClick={() => unmountModal()} className="modal-close-icon fas fa-times"></span>
                </div>
                <div className="modal-body">
                    { props.children }
                </div>
            { props.loadingSpinner ? <div className="loading-spinner"></div> : undefined }
            </div>
        </form>
    );
}