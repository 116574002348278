import { fetchPost } from './apiUtils';

const getAuthToken = async (username, password) => {
    try {
        const response = await fetchPost("/Auth/login", {username: username, password: password}, false);
        if(response.status !== 200) {
            throw new Error("Der Nutzername und das Passwort stimmen nicht überein.");
        }
        const tokenobj = await response.json();
        return tokenobj.token;
    } catch {
        throw new Error("Der Nutzername und das Passwort stimmen nicht überein.");
    }
}

const registerUser = async (username, password) => {
    // placeholder:
    return "Bearer asdf.asdf.asdf";
}

export { getAuthToken, registerUser };