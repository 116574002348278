import React from 'react';
import "./Button.scss";

export const ButtonRow = props => {
  return <div className={`btn-row ${props.className}`}>{ props.children }</div>
}

export const Button = props => {
  return <input 
          className={`btn ${props.className} ${props.active === false ? "inactive" : ""}`}
          type="button"
          onClick={props.onClick}
          value={props.value} />
}

export const IconButton = props => {
  return <button 
          className={`btn btn-sm ${props.className} btn-${props.color}`}
          onClick={props.onClick} >
            <i className={ props.icon }></i>
        </button>;
}