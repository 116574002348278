import React, { useState, useContext, useEffect } from 'react';
import { ApiContext } from '../context/ApiContext';
import { TagModal } from './modals/TagModal';

const EMPTY_TAG = {
    id: 0,
    title: "",
    color: ""
  };

const Item = props => <span
                        className="item"
                        style={{
                                backgroundColor: props.data.color, 
                                color: "white", 
                                border: "none"
                            }} 
                        onClick={ () => props.openModal() }>
                            { props.data.title }
                        </span>;

export const TagSettings = props => {
    const apiContext = useContext(ApiContext);
    const [tagModal, setTagModal] = useState(null);
    const [tags, setTags] = useState([EMPTY_TAG]);

    useEffect(() => {
        const getTags = async () => {
          const tags = await apiContext.tags.getItems();
          setTags(tags);
        }
        getTags();
      }, [apiContext]);

    const reload = () => {
        const getTags = async () => {
            const subjects = await apiContext.tags.getItems();
            setTags(subjects);
        }
        getTags();
    }

    return <>
       { tagModal ? <TagModal
                            data={ tagModal }
                            closeModalAction={ () => {setTagModal(null);reload()} } /> : null} 
        <div className="headline">
            <h3>Tags (Epics)</h3>
        </div>
        <div className="items">
            { tags.map(tag => <Item key={tag.id} data={tag} openModal={() => setTagModal(tag)} />) }
            <span className="item" onClick={ () => setTagModal({id: 0, title:"", color:"#FF5252"}) }><i className="bi bi-plus"></i></span>
        </div>
    </>
}