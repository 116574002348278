import React, { useState } from 'react';
import { Headline } from '../../components/elements/Typography';
import { Button, ButtonRow } from "../../components/form/Button";
import { MsgBoxModal } from "../../components/modal/MsgBoxModal";
import { logout } from "../../utils/tokenUtils";
import { SubjectSettings } from './SubjectSettings';
import { TagSettings } from './TagSettings';
import "./Settings.scss";

export default function Settings () {
  const [showLogoutBox, setshowLogoutBox] = useState(false);

  return <div className="page-container">
    { showLogoutBox ? 
        <MsgBoxModal 
          className="darken"
          title="Abmelden"
          description="Möchtest du dich wirklich abmelden?"
          closeModalAction={ () => setshowLogoutBox(false)}>
            <ButtonRow className="btn-fit-2 no-margin">
              <Button
                className="btn-primary w-50"
                value="Abmelden"
                onClick={() => logout() }/>
              <Button
                className="btn-secondary w-50"
                value="Abbrechen"
                onClick={() => setshowLogoutBox(false) }/>
            </ButtonRow>
        </MsgBoxModal> : null }
    
    <Headline className="margin-bottom" value={ "Einstellungen" } />  

    <SubjectSettings />

    <hr /><br />

    <TagSettings />

    <hr /><br />

    <Button
      className="btn-secondary btn-lg w-100"
      value="Abmelden"
      onClick={() => setshowLogoutBox(true) }/>
    </div>;
}