import { useEffect, useState } from "react"
import App from "./App";
import AuthentificationPage from "./AuthentificationPage";
import { LoadingScreen } from "./LoadingScreen";
import { authTokenIsSet } from './utils/tokenUtils';

export const AppContainer = () => {
    const [userIsLoggedIn, setUserIsLoggedIn] = useState(null);
    useEffect(() => {
        const getToken = async () => {
            const isLoggedIn = await authTokenIsSet();
            setUserIsLoggedIn(isLoggedIn);
        }
        getToken();
    }, [])
    return userIsLoggedIn === null ? <LoadingScreen /> : userIsLoggedIn ? <App /> : <AuthentificationPage />;
}