import React, { useState } from 'react';
import { Button } from '../components/form/Button';
import logo from '../assets/logo.svg';
import './AuthentificationPage.scss';
import { changeThemeColor } from '../utils/renderUtils';
import { LoginModal } from './modals/LoginModal';
import { RegistrationModal } from './modals/RegistrationModal';


function AuthentificationPage() {
  const [ showLoginModal, setShowLoginModal ] = useState(false);
  const [ showRegistrationModal, setShowRegistrationModal ] = useState(false);
  changeThemeColor("#212121");

  return (
    <>
      { showLoginModal ? <LoginModal closeModalAction={() => setShowLoginModal(false)} /> : null }
      { showRegistrationModal ? <RegistrationModal closeModalAction={() => setShowRegistrationModal(false)} /> : null }
      <main className="auth-page">
        <div className="container">
          <img src={logo} width="100" alt="4DO" />
          <div className="btn-container">
            <Button
              className="btn-secondary btn-lg"
              value="Anmelden"
              onClick={() => setShowLoginModal(true)} />    
            <Button
              className="btn-primary btn-lg"
              value="Registrieren"
              onClick={() => setShowRegistrationModal(true) } />  
          </div>
        </div>
      </main>
    </>
  );
}

export default AuthentificationPage;