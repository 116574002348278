import { useState, useEffect, useCallback, useReducer } from "react";
import { filter, prop } from 'ramda';
import { SelectField } from "../../components/form/SelectField";
import "./Onboarding.scss";
import { Button } from "../../components/form/Button";
import { fetchPost } from "../../utils/apiUtils";
import { getAuthToken } from "../../utils/tokenUtils";
import { login } from "../../utils/tokenUtils";

const DEFAULT_STATE = {title: "Auswählen..."};

const POSSIBLE_UNIVERSITYS = [
    DEFAULT_STATE,
    {
        title: "Universität Bremen",
        faculties: [
            DEFAULT_STATE,
        ]
    },
    {
        title: "Hochschule Bremen",
        faculties: [
            DEFAULT_STATE,
            {
                title: "Fakultät 4: Elektrotechnik und Informatik",
                courses: [
                    DEFAULT_STATE,
                    {
                        title: "IS Medieninformatik B. Sc.",
                        semester: [
                            DEFAULT_STATE,
                            {
                                title: "1. Fachsemester"
                            },
                            {
                                title: "2. Fachsemester"
                            },
                            {
                                title: "3. Fachsemester"
                            },
                            {
                                title: "4. Fachsemester"
                            },
                        ] 
                    }
                ]
            }
        ]
    },
    {
        title: "Hochschule Bremerhaven",
        faculties: [
            
        ]
    },
];

const fatArray = [
    {
        id: 0,
        date: "2021-10-04",
        beginHour: "09:45",
        endHour: "11:15",
        room: "I032a-b",
        subject: {
            id: 0,
            title: "Informationssicherheit",
            lecturer: "Prof. Dr. rer. nat. Richard Sethmann"
        }
    },
    {
        id: 0,
        date: "2021-10-05",
        beginHour: "08:00",
        endHour: "09:30",
        room: "I012",
        subject: {
            id: 0,
            title: "Medienformate und Algorithmen",
            lecturer: "Dr. Peter Krug"
        }
    },
    {
        id: 0,
        date: "2021-10-06",
        beginHour: "09:45",
        endHour: "11:15",
        room: "I032C",
        subject: {
            id: 0,
            title: "Softwaretechnik",
            lecturer: "Prof. Dr. Thorsten Teschke"
        }
    },
    {
        id: 0,
        date: "2021-10-06",
        beginHour: "11:30",
        endHour: "16:45",
        room: "I032C",
        subject: {
            id: 0,
            title: "Wagenfeld von A-Z",
            lecturer: "Andreas Teufel"
        }
    },
    {
        id: 0,
        date: "2021-10-07",
        beginHour: "09:45",
        endHour: "11:15",
        room: "I032C",
        subject: {
            id: 0,
            title: "Human Computer Interaction",
            lecturer: "Prof. Dr. Volker Paelke"
        }
    },
    {
        id: 0,
        date: "2021-10-08",
        beginHour: "09:45",
        endHour: "15:00",
        room: "I220",
        subject: {
            id: 0,
            title: "PICKIT",
            lecturer: "Prof. Dr. Volker Paelke"
        }
    },
    {
        id: 0,
        date: "2021-10-08",
        beginHour: "09:45",
        endHour: "15:00",
        room: "I221",
        subject: {
            id: 0,
            title: "Filmproduktion",
            lecturer: "Ralf Carsten Schreier"
        }
    }
];

const importSchedule = async data => {
    const postData = async (data) => {
        const res = await fetchPost("/import/schedule", data);
        if(res.status === 200) return true
        return false;
    }
    return postData(data);
}

const importData = data => {
    importSchedule(data).then((res)=>{
        login(getAuthToken());
    }).catch(
        console.error("Something went terribly wrong!")
    );
}

const dataImportReducer = (state, action) => {
    switch(action.type) {
        case 'SEND_DATA':
            if(state.hasSend === false) {
                state.hasSend = true;
                return importData(state.data);
            }
            break;
        default:
            return false;
    }
}

export const Onboarding = props => {
    const [ university, setUniversity ] = useState(DEFAULT_STATE);
    const [ faculty, setFaculty ] = useState(DEFAULT_STATE);
    const [ course, setCourse ] = useState(DEFAULT_STATE);
    const [ semester, setSemester ] = useState(DEFAULT_STATE);
    const [ loading, setLoading ] = useState(false)
    const [ importer, importData ] = useReducer(dataImportReducer, { hasSend: false, data: fatArray});

    useEffect(() => {
        if(university === DEFAULT_STATE) {
            setFaculty(DEFAULT_STATE);
            setCourse(DEFAULT_STATE);
            setSemester(DEFAULT_STATE);
        }
        if(faculty === DEFAULT_STATE) {
            setCourse(DEFAULT_STATE);
            setSemester(DEFAULT_STATE);
        }
    });

    const doImportData = () => {
        setLoading(true);
        importData({ type: 'SEND_DATA' });
    }

    return <div className="onboarding">
        { !loading ? <>
        <h1>Hallo { props.user.name },</h1>
        <p>schön, dass du dich für 4DO entschieden hast.<br /><br /></p>

        <p>Lass uns nun deinen Stundenplan importieren.</p>

        <SelectField 
            className="w-100"
            title="Universität / Hochschule"
            possibleValues={ POSSIBLE_UNIVERSITYS }
            value={ university }
            onChange={ university => setUniversity(filter(obj => obj.title === university, POSSIBLE_UNIVERSITYS)[0]) }
        />

        { university.faculties ? <SelectField 
            className="w-100"
            title="Fakultät"
            possibleValues={ university.faculties }
            value={ faculty }
            onChange={ faculty => setFaculty(filter(obj => obj.title === faculty, university.faculties)[0]) }
        /> : null}

        { faculty.courses ? <SelectField 
            className="w-100"
            title="Studiengang"
            possibleValues={ faculty.courses }
            value={ course }
            onChange={ course => setCourse(filter(obj => obj.title === course, faculty.courses)[0]) }
        /> : null}

        { course.semester ? <SelectField 
            className="w-100"
            title="Fachsemester"
            possibleValues={ course.semester }
            value={ semester }
            onChange={ semester => setSemester(filter(obj => obj.title === semester, course.semester)[0]) }
        /> : null}

        <div className="btns"> 
            <Button
            className="btn-primary w-100"
            active={ semester !== DEFAULT_STATE }
            onClick={() => semester === DEFAULT_STATE ? null : doImportData()}
            value="Weiter"
            />
            
            <Button
            className="btn-text w-100"
            onClick={() => login(getAuthToken())}
            value="Überspringen"
            />
        </div>
          </> : <>
            <p>Deine Daten werden importiert...</p>
          </>}
    </div>;
}
