import React, { useState, useContext } from 'react';

import { Modal } from "../../../components/modal/Modal"
import { Button, ButtonRow } from '../../../components/form/Button';
import InputField from "../../../components/form/InputField";
import { ApiContext } from '../../context/ApiContext';
import Alert from '../../../components/elements/Alert';

export const SubjectModal = props => {
    const apiContext = useContext(ApiContext);
    const [title, setTitle] = useState(props.data.title);
    const [lecturer, setLecturer] = useState(props.data.lecturer);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const saveInputs = () => {
        setLoadingSpinner(true);

        if(title === "") {
          setErrorMessage("Der Titel darf nicht leer sein.")
          setLoadingSpinner(false);
          return;
        }
        
        if(props.data.id === 0) {
            apiContext.subjects.addItem({
              id: props.data.id,
              title: title,
              lecturer: lecturer
          }).then(success => {
            if(success) {
              setLoadingSpinner(false);
              document.querySelector("#active-modal").classList.add("hide-modal");
              window.setTimeout(() => props.closeModalAction(), 500);
            } else {
              setErrorMessage("Bitte überprüfe deine angegebenen Daten.")
              setLoadingSpinner(false);
            }
          });

          document.querySelector("#active-modal").classList.add("hide-modal");
          window.setTimeout(() => props.closeModalAction(), 500);
        } else {
          apiContext.subjects.saveItem({
              id: props.data.id,
              title: title,
              lecturer: lecturer
          }).then(success => {
            if(success) {
              setLoadingSpinner(false);
              document.querySelector("#active-modal").classList.add("hide-modal");
              window.setTimeout(() => props.closeModalAction(), 500);
            } else {
              setErrorMessage("Bitte überprüfe deine angegebenen Daten.")
              setLoadingSpinner(false);
            }
          });

          document.querySelector("#active-modal").classList.add("hide-modal");
          window.setTimeout(() => props.closeModalAction(), 500);
        }
        
    }

    const deleteItem = () => {
      apiContext.subjects.deleteItem(props.data.id).then(success => {
        if(success) {
          setLoadingSpinner(false);
          document.querySelector("#active-modal").classList.add("hide-modal");
          window.setTimeout(() => props.closeModalAction(), 500);
        } else {
          setErrorMessage("Fehler beim Löschen des Eintrages.")
          setLoadingSpinner(false);
        }
      });

      document.querySelector("#active-modal").classList.add("hide-modal");
      window.setTimeout(() => props.closeModalAction(), 500);
    }

    return <Modal
      className="darken"
      title={ props.data.id === 0 ? "Fach erstellen" : "Fach bearbeiten" }
      closeModalAction={() => props.closeModalAction()}
      loadingSpinner={loadingSpinner}
      onSubmit={saveInputs}>

        { errorMessage ? <Alert type="danger" message={errorMessage} /> : undefined}

        <InputField 
          className="w-100"
          onChange={title => setTitle(title)}
          value={ title }
          title="Titel"
          placeholder="Allgemein"
          />
        <InputField 
          className="w-100"
          onChange={lecturer => setLecturer(lecturer)}
          value={ lecturer }
          title="Dozent / Lehrkraft"
          placeholder="Prof. Dr. Dr. Dracula"
          />

        { props.data.id !== 0 ?
        <ButtonRow className="btn-fit-2 no-margin">
          <Button
            className="btn-secondary-dark w-100"
            onClick={deleteItem}
            value="Löschen"
            />

          <Button
            className="btn-primary w-100"
            onClick={saveInputs}
            value="Speichern"
            />
        </ButtonRow> : <Button
            className="btn-primary w-100"
            onClick={saveInputs}
            value="Speichern"
            /> }
          
    </Modal>;
}