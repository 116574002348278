import React from 'react';
import './MsgBoxModal.scss';

export const MsgBoxModal = (props) => {
    const unmountModal = () => {
        document.querySelector("#active-modal").classList.add("hide-modal");
        window.setTimeout(() => props.closeModalAction(), 500);
    }
    
    return (
        <div className={ `modal-container ${props.className}` } id="active-modal">
            <div onClick={() => unmountModal()} className="modal-background"></div>
            <div className="msg-box-modal">
                <div className="modal-header">
                    { props.title ? (<h1 className="modal-title">{ props.title }</h1>) : null }
                    { props.description ? (<p className="modal-description">{ props.description }</p>) : null }
                    <span onClick={() => unmountModal()} className="modal-close-icon fas fa-times"></span>
                </div>
                <div className="modal-body">
                    { props.children }
                </div>
            </div>
        </div>
    );
}