import React from 'react';
import "./Alert.scss";

const getIcon = type => {
  let icon = "fas fa-";

  switch (type) {
    case "notification":
      icon = icon + "bell";
      break;
    case "times":
      icon = icon + "exclamation-triangle";
      break;
    case "danger":
      icon = icon + "exclamation-triangle";
      break;
    default:
      icon = icon + "bell";
      break;
  }
}

function Alert(props) {
  let icon = props.icon ? getIcon(props.type) : null;

  return (
    <div className={`alert alert-${props.type}`}>
      { icon ? <div className="icon-area"><span className={icon}></span></div> : null }
      { props.message }
    </div>
  );
}

export default Alert;