import React, { useEffect, useState, useContext } from 'react';
import { filter } from 'ramda';

import { Modal } from "../../../components/modal/Modal"
import { Button, ButtonRow } from '../../../components/form/Button';
import InputField from "../../../components/form/InputField";
import { SelectField } from '../../../components/form/SelectField';
import { ApiContext } from '../../context/ApiContext';
import Alert from '../../../components/elements/Alert';

const EMPTY_SUBJECT = {
    id: 0,
    title: "",
    lecturer: ""
  };

export const TimeTableEntryModal = props => {
    const apiContext = useContext(ApiContext);

    const [subjects, setSubjects] = useState([EMPTY_SUBJECT]);
    const [subject, setSubject] = useState(props.data.subject ? props.data.subject.title : EMPTY_SUBJECT.title);
    const [room, setRoom] = useState(props.data.room);
    const [beginHour, setBeginHour] = useState(props.data.beginHour.slice(0,5));
    const [endHour, setEndHour] = useState(props.data.endHour.slice(0,5));
    const [errorMessage, setErrorMessage] = useState(null);
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    useEffect(() => {
        const getSubjects = async () => {
            const subjects = await apiContext.subjects.getItems();
            setSubjects(subjects);
            if(subject === "") setSubject(subjects[0].title)
          }
          getSubjects();
    }, [apiContext]);

    const saveInputs = () => {
        setLoadingSpinner(true);

        if(beginHour > endHour) {
            setErrorMessage("Der Endzeitpunkt darf nicht vor dem Startzeitpunkt liegen.");
            setLoadingSpinner(false);
            return;
        }
        
        let subjectToSave = filter(obj => obj.title === subject, subjects)[0];
        subjectToSave = subjectToSave === undefined ? subject : subjectToSave;

        apiContext.calendar.saveItem({
            
            id: props.data.id,
            beginHour: beginHour,
            endHour: endHour,
            room: room,
            subject: subjectToSave
                
        }).then(() => {
            document.querySelector("#active-modal").classList.add("hide-modal");
            window.setTimeout(() => props.closeModalAction(), 500);
        }).catch(() => {
            setErrorMessage("Es ist ein Fehler aufgetreten.");
            setLoadingSpinner(false);
        });
    }

    const deleteItem = () => {
        setLoadingSpinner(true);

        apiContext.calendar.deleteItem(props.data.id).then(() => {
            document.querySelector("#active-modal").classList.add("hide-modal");
            window.setTimeout(() => props.closeModalAction(), 500);
        }).catch(() => {
            setErrorMessage("Es ist ein Fehler aufgetreten.");
            setLoadingSpinner(false);
        });
    }

    return <Modal
      className="darken"
      title={ "Eintrag bearbeiten" }
      closeModalAction={() => props.closeModalAction()}
      loadingSpinner={loadingSpinner}
      onSubmit={saveInputs}>

        { errorMessage ? <Alert type="danger" message={errorMessage} /> : undefined}
        <InputField 
          className="w-100"
          type="time"
          onChange={time => setBeginHour(time) }
          value={ beginHour }
          w50={ true }
          title="Von"
          />
        <div className="input-spacer"></div>
        <InputField 
          className="w-100"
          type="time"
          onChange={time => setEndHour(time) }
          value={ endHour }
          w50={ true }
          title="Bis"
          />

        <SelectField 
          className="w-100"
          onChange={subject => setSubject(subject)}
          value={ subject }
          possibleValues={ subjects }
          title="Fach"
          placeholder="Allgemein"
          />

        <InputField 
          className="w-100"
          onChange={room => setRoom(room)}
          value={ room }
          title="Raum"
          placeholder="l032a"
        />

        <ButtonRow className="btn-fit-2 no-margin">
          <Button
            className="btn-secondary-dark w-100"
            onClick={deleteItem}
            value="Löschen"
            />

          <Button
            className="btn-primary w-100"
            onClick={saveInputs}
            value="Speichern"
            />
        </ButtonRow>
      </Modal>
} 