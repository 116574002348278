import React from "react";
import { fetchGet, fetchPost, fetchPut, fetchDelete } from "../../utils/apiUtils";

export const defaultApiContext = {
    toDo: {
        getItems: async () => {
            const response = await fetchGet("/ToDo", true);
            const res = await response.json();
            if(res.status === 404) return []
            return res;
        },
        saveItem: async data => {
            const putData = async (data) => {
                const res = await fetchPut("/ToDo/item", data);
                if(res.status === 200) return true;
                return false;
            }
            if(data.tag === "Kein Tag" || data.tag?.title === "") data.tag = null;
            if(data.tag?.color) data.tag.colour = data.tag.color;

            return putData(data);
        },
        addItem: data => {
            const postData = async (data) => {
                const res = await fetchPost("/ToDo/item", data);
                if(res.status === 200) return true
                return false;
            }
            if(data.tag === "Kein Tag" || data.tag?.title === "") data.tag = null;
            if(data.tag?.color) data.tag.colour = data.tag.color;

            return postData(data);
        },
        checkItem: async (id) => {
            const postData = async (id) => {
                const res = await fetchPost(`/ToDo/item/done/${id}`);
                if(res.status === 200) return true
                return false;
            }
            return await postData(id);
        }
    },
    calendar: {
        getItemsForWeekday: async (weekday) => {
            const response = await fetchGet(`/Calendar/${weekday}`, true);
            const res = await response.json();
            if(res.status === 404) return {}
            return res;
        },
        saveItem: async data => {
            const putData = async (data) => {
                const res = await fetchPut("/Calendar/item/schedule", data);
                if(res.status === 200) return true
                return false;
            }
            return putData(data);
        },
        addItem: async data => {
            const postData = async (data) => {
                const res = await fetchPost("/Calendar/item/schedule", data);
                if(res.status === 200) return true
                return false;
            }
            return postData(data);
        },
        deleteItem: async id => {
            const deleteId = async (id) => {
                const res = await fetchDelete(`/Calendar/item/schedule/${id}`);
                if(res.status === 200) return true;
                return false;
            }

            return deleteId(id);
        }
    },
    subjects: {
        getItems: async () => {
            const response = await fetchGet("/Subjects");
            const res = await response.json();
            if(res.status === 404) return []
            return res;
        },
        saveItem: async data => {
            const putData = async (data) => {
                const res = await fetchPut("/Subjects", data);
                if(res.status === 200) return true;
                return false;
            }

            return putData(data);
        },
        addItem: async data => {
            const putData = async (data) => {
                const res = await fetchPost("/Subjects", data);
                if(res.status === 200) return true;
                return false;
            }

            return putData(data);
        },
        deleteItem: async id => {
            const deleteId = async (id) => {
                const res = await fetchDelete(`/Subjects/${id}`);
                if(res.status === 200) return true;
                return false;
            }

            return deleteId(id);
        }
    },
    tags: {
        getItems: async () => {
            const response = await fetchGet("/Tags");
            const res = await response.json();
            if(res.status === 404) return []
            return res;
        },
        saveItem: async data => {
            const putData = async (data) => {
                const res = await fetchPut("/Tags", data);
                if(res.status === 200) return true;
                return false;
            }

            return putData(data);
        },
        addItem: async data => {
            const postData = async (data) => {
                const res = await fetchPost("/Tags", data);
                if(res.status === 200) return true;
                return false;
            }
            return postData(data);
        },
        deleteItem: async id => {
            const deleteId = async (id) => {
                const res = await fetchDelete(`/Tags/${id}`);
                if(res.status === 200) return true;
                return false;
            }

            return deleteId(id);
        }
    } 
}

export const ApiContext = React.createContext(defaultApiContext);