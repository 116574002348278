import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { changeThemeColor } from '../utils/renderUtils';
import ToDoList from './ToDoList';
import TimeTable from './TimeTable';
import Settings from './Settings';
import Navigation from './Navigation';

function App() {
  changeThemeColor("#F7F7F7");
  
  return <Router>
    <Routes>
      <Route path="/" element={ <ToDoList /> } />
      <Route path="/timetable/" element={ <TimeTable /> } />
      <Route path="/settings/" element={ <Settings /> } />
    </Routes>
    <Navigation />
  </Router>;
}

export default App;