import React, { useState } from 'react';
import InputField from '../../components/form/InputField';
import { Button } from '../../components/form/Button';
import { Modal } from '../../components/modal/Modal';
import Alert from '../../components/elements/Alert';
import { Onboarding } from '../Onboarding';
import { fetchPost } from '../../utils/apiUtils';

export const RegistrationModal = (props) => {
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [alert, setAlert] = useState(null);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const validateInputs = () => {
    setLoadingSpinner(true);
    if(password !== password2) {
      setAlert({type: "danger", message: "Die Passwörter stimmen nicht überein."});
      setLoadingSpinner(false);
      return;
    }
    fetchPost("/Auth/new", {username: username, password: password}, false).then((response) => {
      response.json().then(res => {
        if(res.token !== undefined) {
          localStorage.setItem("auth", res.token);
          setShowOnboarding(true);
          setLoadingSpinner(false);
        } else {
          setAlert({type: "danger", message: "Der Nutzername ist bereits vergeben."});
          setLoadingSpinner(false);
        }
      });
    }).catch(() => {
      setAlert({type: "danger", message: "Der Nutzername ist bereits vergeben."});
      setLoadingSpinner(false);
    });
    
  }

  return (<>
    <Modal
      className="ligthen"
      title="Herzlich willkommen!"
      description="Bitte lege nun deine Nutzerdaten an."
      closeModalAction={() => props.closeModalAction()}
      loadingSpinner={loadingSpinner}
      onSubmit={validateInputs}>
      
      { alert ? <Alert type={alert.type} message={alert.message} /> : null }

      <InputField 
          className="w-100"
          onChange={username => setUsername(username)}
          value={username}
          title="Benutzername"
          placeholder="Max Mustermann"
          />

      <InputField
          className="w-100"
          type="password"
          onChange={password => setPassword(password)}
          value={password}
          title="Passwort"
          placeholder=""
          />

      <InputField
          className="w-100"
          type="password"
          onChange={password => setPassword2(password)}
          value={password2}
          title="Passwort wiederholen"
          placeholder=""
          />

      <Button
          className="btn-primary w-100"
          onClick={validateInputs}
          value="Registrieren"
          />
    </Modal>
    
    { showOnboarding ? <Onboarding user={{name: username, password: password}} /> : null }
    
    </>
  );
}