import React, { useEffect, useState, useContext } from 'react';
import { filter, isEmpty } from 'ramda';

import { Modal } from "../../../components/modal/Modal"
import { Button } from '../../../components/form/Button';
import InputField from "../../../components/form/InputField";
import { SelectField } from '../../../components/form/SelectField';
import { ApiContext } from '../../context/ApiContext';
import Alert from '../../../components/elements/Alert';

const EMPTY_SUBJECT = {
  id: 0,
  title: "",
  lecturer: ""
};

const EMPTY_TAG = {
  id: 0,
  title: "",
  color: ""
};

export const ToDoListEntryModal = props => {
    const apiContext = useContext(ApiContext);

    console.log(props.data)

    const [subjects, setSubjects] = useState([EMPTY_SUBJECT]);
    const [tags, setTags] = useState([EMPTY_TAG]);

    const [title, setTitle] = useState(props.data.title);
    const [subject, setSubject] = useState(props.data.subject ? props.data.subject : EMPTY_SUBJECT);
    const [tag, setTag] = useState(props.data.tag ? props.data.tag : EMPTY_TAG);
    const [due, setDue] = useState(props.data.due);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    useEffect(() => {
      const getSubjects = async () => {
        const subjects = await apiContext.subjects.getItems();
        setSubjects(subjects);
        if(subject === EMPTY_SUBJECT && subjects.length > 0) setSubject(subjects[0])
      }
      getSubjects();
      const getTags = async () => {
        const subjects = await apiContext.tags.getItems();
        setTags(subjects);
        if(tag === EMPTY_TAG && tag.length > 0) setTag(tags[0])
      }
      getTags();
    }, [apiContext])

    const cutDueStr = str => str.slice(0, 16);
    const extendDueStr = str => str + due.slice(16);

    const saveInputs = () => {
        setLoadingSpinner(true);

        // validate inputs
        if(title === "") {
          setErrorMessage("Der Titel darf nicht leer sein.")
          setLoadingSpinner(false);
          return;
        }

        let subjectToSave = filter(obj => obj.title === subject, subjects)[0];
        subjectToSave = subjectToSave === undefined ? subject : subjectToSave;

        let tagToSave = filter(obj => obj.title === tag, tags)[0];
        tagToSave = tagToSave === undefined ? tag : tagToSave;

        if(props.data.id !== 0) {
            apiContext.toDo.saveItem({
                id: props.data.id,
                title: title,
                subject: subjectToSave,
                tag: tagToSave,
                due: due,
                done: false
            }).then(success => {
              if(success) {
                setLoadingSpinner(false);
                document.querySelector("#active-modal").classList.add("hide-modal");
                window.setTimeout(() => props.closeModalAction(), 500);
              } else {
                setErrorMessage("Bitte überprüfe deine angegebenen Daten.")
                setLoadingSpinner(false);
              }
          });
        } else {
            apiContext.toDo.addItem({
                id: 0,
                title: title,
                subject: subjectToSave,
                tag: tagToSave,
                due: due,
                done: false
            }).then(success => {
                if(success) {
                  setLoadingSpinner(false);
                  document.querySelector("#active-modal").classList.add("hide-modal");
                  window.setTimeout(() => props.closeModalAction(), 500);
                } else {
                  setErrorMessage("Bitte überprüfe deine angegebenen Daten.")
                  setLoadingSpinner(false);
                }
            })
        }
    }

    return <Modal
      className="darken"
      title={ props.data.id === 0 ? "Aufgabe erstellen" : "Aufgabe bearbeiten" }
      closeModalAction={() => props.closeModalAction()}
      loadingSpinner={loadingSpinner}
      onSubmit={saveInputs}>

      { errorMessage ? <Alert type="danger" message={errorMessage} /> : undefined}

        <InputField 
          className="w-100"
          onChange={title => setTitle(title)}
          value={ title }
          title="Titel"
          placeholder="Make a sandwich"
          />

        <SelectField 
          className="w-100"
          onChange={subject => setSubject(subject)}
          value={ subject }
          possibleValues={ subjects }
          title="Fach"
          placeholder="Allgemein"
          />

        <SelectField 
          className="w-100"
          onChange={tag => setTag(tag)}
          value={ tag }
          defaultValue={ { title: "Kein Tag" } }
          possibleValues={ tags }
          title="Tag (Epic)"
          placeholder="( keins )"
          />

        <InputField 
          className="w-100"
          type="datetime-local"
          onChange={due => setDue(extendDueStr(due)) }
          value={ cutDueStr(due) }
          title="Zu erledigen bis..."
          />

        <Button
          className="btn-primary w-100"
          onClick={saveInputs}
          value="Speichern"
          />
          
    </Modal>;
}