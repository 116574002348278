import React from 'react';
import "./InputField.scss";

function InputField(props) {
  return (<div className={`input-group ${ props.w50 ? "w-50" : "" }`}>
    <label className="input-title">
      { props.title }
    </label>
    <input 
      className={`input-field ${props.className}`} 
      type={props.type}
      onChange={e => props.onChange(e.target.value)}
      value={props.value}
      placeholder={props.placeholder}
      maxlength={props.maxlength ? props.maxlength : 42}
      />
  </div>
  );
}

export default InputField;