import './WeekdaySelect.scss';

export const WeekdaySelect = props => {
    return <div className="input-group weekday-select">
        <span className={`weekday ${props.day === 1 ? "active" : ""}`} onClick={() => props.setWeekday(1)}>
            Mo
        </span>
        <span className={`weekday ${props.day === 2 ? "active" : ""}`} onClick={() => props.setWeekday(2)} >
            Di
        </span>
        <span className={`weekday ${props.day === 3 ? "active" : ""}`} onClick={() => props.setWeekday(3)} >
            Mi
        </span>
        <span className={`weekday ${props.day === 4 ? "active" : ""}`} onClick={() => props.setWeekday(4)} >
            Do
        </span>
        <span className={`weekday ${props.day === 5 ? "active" : ""}`} onClick={() => props.setWeekday(5)} >
            Fr
        </span>
        <span className={`weekday ${props.day === 6 ? "active" : ""}`} onClick={() => props.setWeekday(6)} >
            Sa
        </span>
        <span className={`weekday ${props.day === 0 ? "active" : ""}`} onClick={() => props.setWeekday(0)} >
            So
        </span>
    </div>
}