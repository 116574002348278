export const TimeTableEntry = props => {
    const data = props.data;

    return <div className={`entry-container`} onClick={ () => props.openSettings() }>
                <div className="list-entry calendar">
                    <div className="time">
                        <span className="from">
                            { data.beginHour.slice(0,5) }
                        </span>
                        <span className="to">
                            { data.endHour.slice(0,5) }
                        </span>
                    </div>
                    <div className="content">
                        <span className="title">
                            { data.subject ? data.subject.title : <span>&mdash;</span> }
                        </span>
                        <span className="lecturer">
                            <i className="bi bi-person-video3"></i>
                            { data.subject ? data.subject.lecturer : <span>&mdash;</span> }
                        </span>
                        <span className="room">
                            <i className="bi bi-geo-fill"></i>
                            { data.room !== "" ? data.room : <span>&mdash;</span> }
                        </span>
                    </div>
                </div>
            </div>
}