import { React, useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { isNil } from 'ramda';

import { Headline, SubHeadline } from '../../components/elements/Typography';
import { ButtonRow, IconButton } from '../../components/form/Button';
import { ApiContext } from '../context/ApiContext';
import { NewTimeTableEntryModal } from './modal/NewTimeTableEntryModal';
import { List } from '../../components/List';
import { TimeTableEntry } from './TimeTableEntry';
import { TimeTableEntryModal } from './modal/TimeTableEntryModal';

const TIME_TABLE_LIST_ENTRY_DEFAULT = {
  id: 0,
  day: DateTime.now().weekday,
  schedule: [
    {
      id: 0,
      beginHour: "08:00",
      endHour: "09:30",
      room: "",
      subject: {
        id: 0,
        title: "",
        lecturer: ""
      }
    }
  ]
}

const getHeadline = (date) => {
  return date.toLocaleString({ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
}

const getSubHeadline = (itemsCount, date) => {
  const weekday = date.toLocaleString({ weekday: 'long'});
  if(itemsCount === 0) return <>Für { weekday } ist dein Kalender leer.</>;
  const highlightedText = itemsCount === 1 ? "ein Termin" : `${ itemsCount } Termine`;
  return <>{ weekday }s {itemsCount === 1 ? "steht" : "stehen"} <span className="highlighted">{ highlightedText }</span> an.</>
}

const convertWeekdayFormat = weekday => weekday === 7 ? 0 : weekday;

export default function TimeTable () {
  const apiContext = useContext(ApiContext);
  const [ calendarEntries, setCalendarEntries ] = useState([])
  const [ newEntryModal, setNewEntryModal ] = useState(null);
  const [ entryModal, setEntryModal ] = useState(null);
  const [ dates, setDates ] = useState({
      activeDay: convertWeekdayFormat(DateTime.now().weekday),
      displayDate: DateTime.now()
  });

  useEffect(() => {
    const getCalendarItems = async () => {
      let entries = await apiContext.calendar.getItemsForWeekday(dates.activeDay);
      if(isNil(entries)) setCalendarEntries([]);
      else setCalendarEntries(entries);
    }
    getCalendarItems();
  }, [dates])

  const updateItems = () => {
    const getCalendarItems = async () => {
      let entries = await apiContext.calendar.getItemsForWeekday(dates.activeDay);
      if(isNil(entries)) setCalendarEntries([]);
      setCalendarEntries(entries);
    }
    getCalendarItems();
  }

  const showTomorrow = () => {
    const newDisplayDate = dates.displayDate.plus({days: 1});
    const weekday = convertWeekdayFormat(newDisplayDate.weekday);

    setDates({
      activeDay: weekday,
      displayDate: newDisplayDate});
  }

  const showYesterday = () => {
    const newDisplayDate = dates.displayDate.minus({days: 1});
    const weekday = convertWeekdayFormat(newDisplayDate.weekday);

    setDates({
      activeDay: weekday,
      displayDate: newDisplayDate});
  }

  const headline = getHeadline(dates.displayDate);
  const subheadline = getSubHeadline(calendarEntries.length === undefined ? 0 : calendarEntries.length, dates.displayDate);

  let defaultTimeTableEntry = TIME_TABLE_LIST_ENTRY_DEFAULT;
  defaultTimeTableEntry.day = dates.activeDay;

  return  <>
            <div className="page-container">
              { newEntryModal ? <NewTimeTableEntryModal day={ newEntryModal.day } data={ newEntryModal.schedule[0] } closeModalAction={() => { setNewEntryModal(null); updateItems(); }} /> : null }
              { entryModal ? <TimeTableEntryModal date={ dates.displayDate } data={ entryModal } closeModalAction={() => { setEntryModal(null); updateItems(); }} /> : null }

              <Headline value={ headline } />  
              <SubHeadline value={ subheadline } />

              <ButtonRow>
                <IconButton color="primary" icon="bi bi-plus-lg" onClick={ () => setNewEntryModal(defaultTimeTableEntry)} />
                { /* <IconButton color="secondary" icon="bi bi-funnel-fill" /> */ }
              </ButtonRow>

              <List>
                { Array.isArray(calendarEntries) ? calendarEntries?.map(entry => <TimeTableEntry data={entry} openSettings={ () => setEntryModal(entry) } />) : null}
              </List>
            <ButtonRow className="">
              <IconButton color="grey" icon="bi bi-arrow-left" onClick={ () => showYesterday()} />
              <IconButton color="grey" icon="bi bi-arrow-right" onClick={ () => showTomorrow()} />
            </ButtonRow>
            </div>
          </>;
}