import { DateTime } from 'luxon';
import { dissoc, invertObj } from 'ramda';

const translate = (key, value) => {
    let translatedString;
    switch(key) {
        case 'months':
            translatedString = value === 1 ? "Monat" : "Monate";
            break;
        case 'days':
            translatedString = value === 1 ? "Tag" : "Tagen";
            break;
        case 'hours':
            translatedString = value === 1 ? "Stunde" : "Stunden";
            break;
        case 'minutes':
            translatedString = value === 1 ? "Minute" : "Minuten";
            break;
        default:
            translatedString = '';
    }

    return translatedString;
}

export const convertDue = iso => {
    const date = DateTime.fromISO(iso);
    const dur = DateTime.now().diff(date, ['months', 'days', 'hours', 'minutes']);
    const isDue = date > DateTime.now();

    const durObj = invertObj(dissoc('0', invertObj(dur.toObject())));
    const firstKey = Object.keys(durObj)[0];
    const firstValue = Math.floor(Object.values(durObj)[0]);

    if(firstValue === 0) {
        return "jetzt"
    }

    if(!isDue) {
        return `vor ${firstValue} ${translate(firstKey, firstValue)}`;
    } else {

        return `in ${firstValue * -1} ${translate(firstKey, firstValue * -1)}`;
    }

}

export const TODO_LIST_ENTRY_DEFAULT = {
    id: 0,
    title: "",
    subject: null,
    due: DateTime.now().toISO().slice(0, 19),
    tag: null
}