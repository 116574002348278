import React from 'react';
import "./InputField.scss";

/**
 * select field component
 * @param {*} props should have a title, a onChange-function, a value (or a placeholder) and possible values array with an unique id and a name per item
 * @returns a select field
 */
export const SelectField = props => {
  return (<div className="input-group">
    <label className="input-title">
      { props.title }
    </label>
    <select 
      className={`select-field ${props.className}`} 
      onChange={e => props.onChange(e.target.value)}
      value={props.value.title}
      >
      { props.defaultValue ? <option>{ props.defaultValue.title }</option> : null }
      { props.possibleValues
            .map(item => <option key={ item.id } >{ item.title }</option>) }       
    </select>
  </div>
  );
}