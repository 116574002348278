import { getAuthToken } from "./tokenUtils";

const backendAddr = "https://todo.peasantpath.com/api/v1";
// /swagger für doku

export const fetchGet = async (url, auth = true) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': auth ? getAuthToken() : ''
    };
    const response = await fetch(`${backendAddr}${url}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    }).catch(() => { throw new Error() });
    return response;
  } catch {
    return false;
  }
}

export const fetchPost = async (url, data, auth = true) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': auth ? getAuthToken() : ''
    };
    const response = await fetch(`${backendAddr}${url}`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    }).catch(() => { throw new Error() });
    return response;
  } catch {
    return false;
  }
}

export const fetchPut = async (url, data, auth = true) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': auth ? getAuthToken() : ''
    };
    const response = await fetch(`${backendAddr}${url}`, {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        headers: headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    }).catch(() => { throw new Error() });
    return response;
  } catch {
    return false;
  }
}

export const fetchDelete = async (url, data, auth = true) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': auth ? getAuthToken() : ''
    };
    const response = await fetch(`${backendAddr}${url}`, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
        headers: headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    }).catch(() => { throw new Error() });
    return response;
  } catch {
    return false;
  }
}